import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { GetRequestSWR } from "../../lib/fetcher"
import { loadingPage } from "../Placeholder/LoadingPage"
import errorPlaceholder from "../Placeholder/Error"
import InspectionCard from "./components/InspectionCard"
import { db } from "../../lib/db"
import { useLiveQuery } from "dexie-react-hooks";
import { downloadInspections } from "../../lib/actions/inspections"
import PageHeader from "../PagesNavigation/PageHeader"


export default function App() {

    // Redux
    const dispatch = useDispatch();
    const auth_token = useSelector(state => state.auth.auth_token)

    // State
    const [localLoading, setLocalLoading] = useState(true)

    const localInspections = useLiveQuery(async () => {
        return await db.inspections.toArray()
    });

    // Effect
    const { isLoading, data, error, cache } = GetRequestSWR(`/inspections?&recent_inspections=true`, null, true, true, auth_token)

    useEffect(async () => {
        setLocalLoading(true)
        if (data?.inspections && data?.inspections.length > 0) {
            await downloadInspections(data?.inspections)
            setLocalLoading(false)
        } else {
            setLocalLoading(false)
        }
    }, [data])

    if (isLoading) {
        return (
            <> {loadingPage()} </>
        )
    }

    if (error) {
        if (error?.response?.data?.errors[0] === "Faça o Login Novamente!") {
            cache.clear();
            dispatch({ type: 'AUTH_ERROR', });
        }
        return (
            <> {errorPlaceholder(error)} </>
        )
    }

    return (
        <>
            <PageHeader
                isSticky
                title="Vistorias"
            />
            {!isLoading && !localLoading && localInspections && localInspections.map((el) => (
                <InspectionCard
                    key={el.id}
                    el={el}
                />
            ))}
            <footer className={`bg-white`}>
            <div className="max-w-7xl mx-auto h-20 justiy-center items-center px-4 sm:px-6 lg:px-8 flex">
                <div className="text-lg font-bold ml-4 text-gray-900 mx-auto text-center">
                    V 1.0.0
                </div>
            </div>
        </footer>
        </>
    );
}